<template>
  <div class="mt-vw-8-to-24 overflow-x-hidden text-white" ref="containerRef">
    <ul v-for="row in rows" class="flex even:justify-end">
      <li
        class="h-vw-20-to-48 w-vw-56-to-100 flex-none rounded-full bg-gradient-to-r from-transparent to-white/50"
      ></li>
      <li v-for="col in row" class="w-vw-56-to-100 flex-none">
        <AppPill class="h-vw-20-to-48" :class="col.bgColor">
          <IconComponent
            class="size-vw-6-to-14 flex-none"
            :icon="col.icon"
          /><span v-html="col.content" class="whitespace-nowrap"></span>
        </AppPill>
      </li>
      <li
        class="h-vw-20-to-48 w-vw-56-to-100 flex-none rounded-full bg-gradient-to-l from-transparent to-white/50"
      ></li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap'
defineProps<{
  rows: { icon: string; content: string; bgColor: string }[][]
}>()
const mm = gsap.matchMedia()
const containerRef = ref()
onMounted(() => {
  mm.add('(max-width: 1535px)', () => {
    parallaxAnimation(true)
  })
  mm.add('(min-width: 1536px) and (max-width: 1919px)', () => {
    parallaxAnimation(false, 2)
  })
  mm.add('(min-width: 1920px) and (max-width: 2559px)', () => {
    parallaxAnimation(false, -2)
  })
  mm.add('(min-width: 2560px)', () => {
    parallaxAnimation(false, -4)
  })
})

function parallaxAnimation(isMobile: boolean, multiplier = 1) {
  const uls = containerRef.value.querySelectorAll('ul')
  const scrollWidths = uls[0].scrollWidth
  uls.forEach((ul: HTMLElement, index: number) => {
    const halfPillWidth = scrollWidths / ul.querySelectorAll('li').length / 2
    gsap.fromTo(
      ul,
      {
        transform: () =>
          `translateX(${index % 2 === 0 ? halfPillWidth * -1 : halfPillWidth}px)`,
      },
      {
        transform: () =>
          isMobile
            ? `translateX(${index % 2 === 0 ? scrollWidths * -1 + window.innerWidth + halfPillWidth : scrollWidths - window.innerWidth - halfPillWidth}px)`
            : `translateX(${index % 2 === 0 ? halfPillWidth * multiplier * -1 : halfPillWidth * multiplier}px)`,
        ease: 'none',
        scrollTrigger: {
          trigger: containerRef.value,
          start: isMobile ? 'bottom bottom' : 'top bottom',
          end: isMobile ? 'top top' : 'bottom top',
          scrub: true,
          invalidateOnRefresh: true,
        },
      }
    )
  })
}
</script>
